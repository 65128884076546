import React from "react"
import CloseIcon from "../Icons/CloseIcon"
import {
  Modal,
  ModalTitle,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
} from "../Modal"
import PrivacyPolicy from "../Content/PrivacyPolicy"

class ContentPopup extends React.Component {
  render() {
    const { isVisible } = this.props
    return (
      <>
      <Modal
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body">
                <CloseButton className="close" onClick={this.props.onClose}><CloseIcon /></CloseButton>
                <ModalTitle className="modal-title"><h2>Privacy Policy</h2></ModalTitle>
                <PrivacyPolicy />
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisible && (
        <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
      )}
      </>
    )
  }
}

export default ContentPopup
