import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet";
import {Section, SectionPageTitle} from "../components/Section"
import CallNow from "../components/CallNow"
import CheckoutForm from "../components/CheckoutForm"
import styled from "styled-components"
import { ArrowLeftIcon } from "../components/Icons"

const BackButton = styled(Link)`
    position: relative;
    z-index: 10;
    float: right;
    padding-right: 50px;
    display: flex;
    align-items: center;
    color: #0B619B;

    svg {
        fill: #0B619B;
        margin-right: 10px;
    }
    
    &:hover {
        color: #FC0002;

        svg {
            fill: #FC0002;
        }
    }

    @media(max-width: 991px) {
        padding-right: 0;
    }
`


const CheckoutPage = ({ location }) => {
    return (
        <Layout location={location}>
        <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
        <Seo title="Checkout" description="Checkout" />
        <Section
            pt="215px"
            pb="90px"
            xpt="120px"
            xpb="60px"
            bgColor="#fff"
        >
            <div className="container">
                <BackButton to="/cart"><ArrowLeftIcon /> Back</BackButton>
                <SectionPageTitle textAlign="left" mb="30px">BILLING DETAILS</SectionPageTitle>
                <CheckoutForm location={location} />
            </div>
        </Section>
        <CallNow />
        </Layout>
    )
}

export default CheckoutPage